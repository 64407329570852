import { PolygonDrawer } from "./PolygonDrawer";
import { TriangleDrawer } from "./TriangleDrawer";
export async function loadGenericPolygonShape(engine, refresh = true) {
    await engine.addShape("polygon", new PolygonDrawer(), refresh);
}
export async function loadTriangleShape(engine, refresh = true) {
    await engine.addShape("triangle", new TriangleDrawer(), refresh);
}
export async function loadPolygonShape(engine, refresh = true) {
    await loadGenericPolygonShape(engine, refresh);
    await loadTriangleShape(engine, refresh);
}
